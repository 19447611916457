'use client';

import {Fragment} from 'react';
import {m as motion} from 'motion/react';
import {useRouter} from 'next/navigation';
import * as Form from '@radix-ui/react-form';
import {useMutation} from '@tanstack/react-query';
import {arrowLeftSLine, arrowRightLine, checkLine, deleteBin3Line} from 'src/utils/icons';
import ResponsiveModal, {modalTransition} from '../ResponsiveModal';
import isProperPlace from 'src/utils/isProperPlace';
import traitRatings from 'src/utils/traitRatings';
import useMyReview from 'src/hooks/useMyReview';
import useReviewModal from './useReviewModal';
import ConfirmDialog from '../ConfirmDialog';
import {ratingColors} from 'src/constants';
import TextInput from '../form/TextInput';
import RatingStars from './RatingStars';
import fetcher from 'src/utils/fetcher';
import {caveat} from 'src/utils/fonts';
import BaseButton from '../BaseButton';
import RemixIcon from '../RemixIcon';
import TraitIcon from '../TraitIcon';

type TraitItem = {
  question: React.ReactNode;
  description: string;
};

const traitsInfo: TraitItem[] = [
  {
    question: (
      <span>
        How were the <strong>people</strong>?
      </span>
    ),
    description: "We're looking for the smile per day ratio here."
  },
  {
    question: (
      <span>
        How were the <strong>food and drinks</strong>?
      </span>
    ),
    description: 'Delicious? Interesting? Worth trying out?'
  },
  {
    question: (
      <span>
        How were the <strong>public spaces</strong>?
      </span>
    ),
    description: 'Walkability, public transport, parks, squares.'
  },
  {
    question: (
      <span>
        How was the <strong>value for money</strong>?
      </span>
    ),
    description: 'What do you get for your hard-earned cash?'
  },
  {
    question: (
      <span>
        How <strong>safe</strong> did it feel?
      </span>
    ),
    description: 'Would you be fine at night alone?'
  }
];

const traits = traitRatings.map((trait, index) => ({
  name: trait.type,
  ratingKey: trait.reviewKey,
  question: traitsInfo[index].question,
  description: traitsInfo[index].description
}));

type Props = {
  children?: React.ReactNode;
  place: Partial<Place> | PlacePageType;
  initialReview?: Review;
  controlledIsOpen?: boolean;
  controlledSetIsOpen?: (open: boolean) => void;
  onUpdated?: () => void;
  session: IronSession | null;
};

const RatePlaceModal = ({
  children,
  place,
  initialReview,
  controlledIsOpen,
  controlledSetIsOpen,
  onUpdated,
  session
}: Props) => {
  const router = useRouter();

  const {review, refetchMyReview} = useMyReview({
    countryCode: place.countryCode,
    placeNameId: place.nameId,
    initialReview,
    session
  });

  const showTraits = isProperPlace(place.placeType || '');

  const {
    isOpen,
    setIsOpen,
    section,
    setSection,
    setMainRating,
    setTraitRating,
    reviewState,
    setReviewText,
    submitTextReview,
    reset
  } = useReviewModal({
    place,
    review,
    refetchReview: refetchMyReview,
    controlledIsOpen,
    controlledSetIsOpen,
    onUpdated
  });

  const {mutateAsync: deleteReview, isPending: isDeletingReview} = useMutation({
    mutationFn: async () =>
      fetcher({
        url: `/api/reviews/${review?._id}`,
        options: {method: 'DELETE'}
      }),
    onSuccess: () => {
      if (onUpdated) onUpdated();
      refetchMyReview();
      reset();
      router.refresh();
    }
  });

  return (
    <ResponsiveModal
      trigger={children}
      className="w-[460px]"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      ariaLabel={`Rate ${place.name}`}
    >
      {section === 'main' ? (
        <motion.div key="main" layout className="flex flex-col items-center px-4" transition={modalTransition}>
          <h2 className="text-2xl text-center my-2">How was {place.name}?</h2>

          <p className="text-center my-2">
            <strong>Did you love it and want to go back?</strong> Or was it a bit "meh", perhaps even a place to avoid?
          </p>

          <div className="flex flex-col mt-6">
            <RatingStars className="mb-2" rating={reviewState?.mainRating} onRate={setMainRating} />

            <div className={`flex justify-between ${caveat.className} text-xl text-(--gray-text)`}>
              <span className="-ml-4">Hate it.</span>
              <span className="-mr-2">Love it!</span>
            </div>
          </div>
        </motion.div>
      ) : null}

      {section === 'traits' ? (
        <Fragment key="traits">
          <motion.div key="backToMain" layout className="absolute left-6 top-4" transition={modalTransition}>
            <BaseButton onPress={() => setSection('main')} className="h-[32px] text-sm" buttonVariant="secondary">
              <RemixIcon icon={arrowLeftSLine} color="var(--text-color)" size={18} className="mr-1 -ml-2" />
              Main Rating
            </BaseButton>
          </motion.div>

          <motion.div
            layout
            key="traits-div"
            transition={modalTransition}
            className="flex flex-col items-center mt-14 [--icon-bg-color:#fcfcfc] dark:[--icon-bg-color:#160f19]"
          >
            <div className="flex flex-col">
              {traits.map((trait) => {
                const activeColor = reviewState?.[trait.ratingKey]
                  ? ratingColors[(reviewState[trait.ratingKey] || 1) - 1]
                  : 'var(--gray-text)';

                return (
                  <div key={trait.name} className="flex gap-6">
                    <div
                      className="h-[36px] w-[36px] rounded-[14px] flex items-center justify-center"
                      style={{backgroundColor: activeColor}}
                    >
                      <TraitIcon type={trait.name} color={'var(--icon-bg-color)'} />
                    </div>

                    <div className="text-lg flex flex-col mb-6">
                      <span className="font-semibold [&_strong]:font-bold leading-tight">{trait.question}</span>
                      <span className="text-sm text-(--gray-text)">{trait.description}</span>

                      <RatingStars
                        className="mt-2"
                        starSize={32}
                        rating={reviewState?.[trait.ratingKey]}
                        onRate={(rating) => setTraitRating(trait.ratingKey, rating)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="flex mt-2 w-full gap-4">
              <BaseButton
                icon={checkLine}
                label="I'm done"
                buttonVariant="translucent"
                onPress={() => setIsOpen(false)}
              />
              <BaseButton
                icon={arrowRightLine}
                label="Write a review"
                buttonVariant="primary"
                className="grow"
                onPress={() => setSection('review')}
              />
            </div>
          </motion.div>
        </Fragment>
      ) : null}

      {section === 'review' ? (
        <Fragment key="review">
          <motion.div key="backToPrevious" layout className="absolute left-6 top-4" transition={modalTransition}>
            <BaseButton
              onPress={() => setSection(showTraits ? 'traits' : 'main')}
              className="h-[32px] text-sm"
              buttonVariant="secondary"
            >
              <RemixIcon icon={arrowLeftSLine} color="var(--text-color)" size={18} className="mr-1 -ml-2" />
              {showTraits ? 'Traits' : 'Main Rating'}
            </BaseButton>
          </motion.div>

          <motion.div key="review-div" layout className="flex flex-col mt-10" transition={modalTransition}>
            <p>
              <strong>How was {place.name}?</strong> What to do, what to avoid? What to eat and where to go?
            </p>

            <Form.Root className="flex flex-col mt-4">
              <TextInput
                name="review"
                label={`${place.name} was...`}
                textarea
                minRows={5}
                defaultValue={reviewState?.text}
                onChange={(e) => setReviewText(e.target.value)}
              />
            </Form.Root>

            <BaseButton
              label="Send"
              icon={checkLine}
              buttonVariant="primary"
              className="mt-2 w-full"
              onPress={() => {
                submitTextReview();
                setIsOpen(false);
              }}
            />
          </motion.div>
        </Fragment>
      ) : null}

      {section !== 'main' && review ? (
        <motion.div key="deleteReview" layout className="absolute right-14 top-4" transition={modalTransition}>
          <ConfirmDialog
            title="Delete review"
            message="Are you sure? You will not be able to recover your review."
            confirmText="Delete my review"
            confirmButtonClassName="bg-(--error-color) shadow-none dark:shadow-none"
            onConfirm={deleteReview}
            isLoading={isDeletingReview}
          >
            {({openModal}) => {
              return (
                <BaseButton
                  onPress={openModal}
                  className="h-[32px] w-[32px] text-sm"
                  buttonVariant="secondary"
                  icon={deleteBin3Line}
                  iconSize={16}
                />
              );
            }}
          </ConfirmDialog>
        </motion.div>
      ) : null}
    </ResponsiveModal>
  );
};

export default RatePlaceModal;
