import {useState} from 'react';
import {twMerge} from 'tailwind-merge';
import ResponsiveModal from './ResponsiveModal';
import isBoolean from 'src/utils/isBoolean';
import BaseButton from './BaseButton';

type Props = {
  onConfirm: () => void;
  title?: string;
  message: string;
  confirmText?: string;
  children?: (options: {openModal: () => void}) => React.ReactNode;
  trigger?: React.ReactNode;
  confirmButtonStyle?: React.CSSProperties;
  confirmButtonClassName?: string;
  controlledIsOpen?: boolean;
  controlledSetIsOpen?: (open: boolean) => void;
  isLoading?: boolean;
};

const ConfirmDialog = ({
  title,
  message,
  confirmText,
  onConfirm,
  children,
  confirmButtonStyle,
  confirmButtonClassName,
  trigger,
  controlledIsOpen,
  controlledSetIsOpen,
  isLoading
}: Props) => {
  const [localIsOpen, localSetOpen] = useState(false);

  const isOpen = isBoolean(controlledIsOpen) ? controlledIsOpen : localIsOpen;

  const setOpen = (open: boolean) => {
    return controlledSetIsOpen ? controlledSetIsOpen(open) : localSetOpen(open);
  };

  const confirm = async () => {
    await onConfirm();

    setOpen(false);
  };

  return (
    <>
      {children ? children({openModal: () => setOpen(true)}) : null}

      <ResponsiveModal
        trigger={trigger}
        isOpen={isOpen}
        setIsOpen={setOpen}
        onClose={() => setOpen(false)}
        ariaLabel={title}
      >
        <p className="py-8 max-w-[350px] text-lg leading-tight text-center">{message}</p>

        <div className="flex gap-4">
          <BaseButton className="grow" label="Cancel" buttonVariant="secondary" onPress={() => setOpen(false)} />

          <BaseButton
            label={confirmText || 'Confirm'}
            buttonVariant="primary"
            className={twMerge('grow', confirmButtonClassName)}
            style={confirmButtonStyle}
            onPress={confirm}
            isLoading={isLoading}
          />
        </div>
      </ResponsiveModal>
    </>
  );
};

export default ConfirmDialog;
